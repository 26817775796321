import React from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardMedia, 
  CardContent, 
  List, 
  ListItem, 
  ListItemText,
  Container,
  Divider
} from '@mui/material';
import CorporateTrainingPic from '../assets/CorporateTrainingPic.png';

const TrainingProgram = ({ title, highlight, overview, learningPoints, outcome }) => (
  <Box mb={4}>
    <Box gap={0.6} display="flex">
    <Typography variant="h6" gutterBottom>
      {title}
    </Typography>
    <Typography variant="h6" color="#143BE2" gutterBottom>
      {highlight}
    </Typography>
    </Box>
    <Typography variant="body1" paragraph>
      <strong>Program Overview:</strong> {overview}
    </Typography>
    <Typography variant="body1" gutterBottom>
      <strong>What You'll Learn:</strong>
    </Typography>
    <List dense>
      {learningPoints.map((point, index) => (
        <ListItem key={index}>
          <ListItemText primary={`• ${point}`} />
        </ListItem>
      ))}
    </List>
    <Typography variant="body1" paragraph>
      <strong>Program Outcome:</strong> {outcome}
    </Typography>
  </Box>
);

const CorporateTrainingPage = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Corporate Training
        </Typography>
        
        <Card elevation={0} >
          <CardMedia
            component="img"
            height="500"
            image={CorporateTrainingPic}
            alt="Corporate training session"
          />
          <CardContent>
            <Typography variant="body1" >
              At DecisionOpt, we offer specialized training programs tailored to meet the needs of corporate clients. Our programs are designed to equip your team with practical skills and knowledge in Operations Research (OR), enabling them to tackle complex decision-making challenges and drive efficiency in your organization.
            </Typography>
            
            <Typography sx={{mt:2}} variant="h6" gutterBottom>
              Why Choose DecisionOpt for Your Training Needs?
            </Typography>
            
            <List>
              <ListItem>
                <ListItemText primary="• Industry Expertise: Our trainers are seasoned OR professionals with extensive experience across various industries, including e-commerce, oil & gas, manufacturing, and supply chain." />
              </ListItem>
              <ListItem>
                <ListItemText primary="• Practical Focus: We emphasize hands-on learning, ensuring participants can apply what they learn directly to their work." />
              </ListItem>
              <ListItem>
                <ListItemText primary="• Customized Solutions: Training programs can be tailored to fit the specific needs and challenges of your organization." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        
        <Divider sx={{ mb: 4 }} />
        
        <TrainingProgram
          title="1. Hands-on OR for"
          highlight="Software Developers"
          overview="This program is designed for software developers looking to enhance their understanding of Operations Research. Participants will gain foundational knowledge of OR concepts and engage in practical, hands-on projects that mimic real-world scenarios."
          learningPoints={[
            "Introduction to the basics of Operations Research",
            "Hands-on experience with OR projects and tools",
            "Techniques for debugging OR code effectively",
            "How to perform scenario analysis for decision support",
            "Skills to maintain and support decision support tools"
          ]}
          outcome="By the end of this program, participants will be equipped to contribute to OR projects, effectively support decision support tools, and apply their skills to improve operational processes within your organization."
        />
        
        <TrainingProgram
          title="2. Advanced OR for"
          highlight="Junior OR Scientists"
          overview="This advanced training program is tailored for junior OR engineers and scientists who are eager to deepen their expertise in Operations Research. The focus is on developing advanced skills in modeling, performance improvement, and problem-solving."
          learningPoints={[
            "Advanced techniques for efficient problem modeling",
            "How to choose the right OR approach for different scenarios",
            "Strategies for model performance improvement",
            "Best practices in OR coding",
            "Efficient debugging of complex model issues"
          ]}
          outcome="Upon completing this program, participants will possess the advanced skills needed to design and optimize complex models, improve the performance of OR solutions, and tackle challenging decision-making problems with confidence."
        />
      </Box>
    </Container>
  );
};

export default CorporateTrainingPage;