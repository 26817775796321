import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ContactModal from "./ContactModal";

const HeroSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const handleOpenContactModal = () => {
    setIsContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false);
  };
  return (
    <Box
      display="flex"
      justifyContent={isMobile ? "center" : "space-between"}
      sx={{
        py: { xs: 5, md: 10 },
        px: { xs: 3, md: 19 },
        backgroundColor: "#F2F2F2",
      }}
    >
      <Box
        sx={{
          textAlign: { xs: "center", md: "left" },
          maxWidth: { xs: "100%", md: "auto" },
        }}
      >
        <Typography sx={{ fontSize: { xs: 32, md: 48 } }} component="h1">
          Decision Intelligence
        </Typography>
        <Typography sx={{ fontSize: { xs: 32, md: 48 } }} component="h1">
          for <strong>Optimized</strong>
        </Typography>
        <Typography
          sx={{ fontSize: { xs: 32, md: 48 } }}
          component="h1"
          gutterBottom
        >
          Business Outcomes
        </Typography>
        <Typography sx={{ fontSize: { xs: 14, md: 16 } }}>
          DecisionOpt delivers proven optimization solutions
        </Typography>
        <Typography sx={{ fontSize: { xs: 14, md: 16 } }}>
          tailored to your unique <strong>challenges</strong>.
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#143BE2",
            fontSize: { xs: 14, md: 16 },
            fontWeight: 500,
            padding: { xs: "4px 12px", md: "4px 16px" },
            marginTop: { xs: 2, md: 3 },
            textTransform: "none",
            borderRadius: "6px",
          }}
          onClick={() => handleOpenContactModal()}
        >
          Get started
        </Button>
        <ContactModal
          open={isContactModalOpen}
          onClose={handleCloseContactModal}
        />
      </Box>
    </Box>
  );
};

export default HeroSection;
