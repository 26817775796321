import React, { useState } from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ContactModal from "./ContactModal";

const GetStarted = ({
  title,
  content,
  buttonText,
  googleForm,
  backgroundColor,
  textColor,
  topMargin,
  useGoogleForm = false,
  bottomMargin = 0,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const handleOpenContactModal = () => {
    setIsContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false);
  };
  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        color: "white",
        borderRadius: "16px",
        px: { xs: 2, sm: 8, md: 16 }, // Reduced padding
        py: { xs: 8, sm: 8, md: 8 }, // Reduced padding
        textAlign: "center",
        maxWidth: { xs: "100%", sm: "500px" }, // Full width on mobile
        width: "90%",
        margin: "auto",
        mt: { xs: 12, sm: 12, md: topMargin ?? 12 }, // Reduced top margin
        mb: { xs: 12, sm: 12, md: bottomMargin ?? 12 }, // Reduced bottom margin
      }}
    >
      <Typography
        sx={{ fontSize: { xs: 24, sm: 30, md: 36 }, color: textColor }}
        gutterBottom
      >
        {title}
      </Typography>
      <Typography
        sx={{ fontSize: { xs: 14, sm: 16 }, mb: 3, color: textColor }}
      >
        {content}
      </Typography>
      <Button
        variant="contained"
        onClick={() =>
          useGoogleForm
            ? window.open(googleForm, "_blank")
            : handleOpenContactModal()
        }
        sx={{
          backgroundColor: "black",
          borderRadius: 3,
          color: "white",
          textTransform: "none",
          fontSize: { xs: 16, sm: 18 },
          py: 1,
          px: 2,
          "&:hover": {
            backgroundColor: "#333",
          },
        }}
      >
        {buttonText}
      </Button>
      <ContactModal
        open={isContactModalOpen}
        onClose={handleCloseContactModal}
      />
    </Box>
  );
};

export default GetStarted;
