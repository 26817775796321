import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import WhyDecisionOpt from "./components/WhyDecisionOpt";
import Testimonials from "./components/Testimonials";
import WorkDescription from "./components/WorkDescription";
import GetStarted from "./components/GetStarted";
import Footer from "./components/Footer";
import { Box, useMediaQuery } from "@mui/material";
import CorporateTraining from "./components/CorporateTraining";
import HiringUpskilling from "./components/HiringUpskilling";
import ConsultingStrategy from "./components/ConsultingStrategy";
import AboutUs from "./components/AboutUs";
import RightBlock from "./assets/RightBlock.svg";
import ScrollToTop from "./components/ScrollToTop";
import ORSchoolOverview from "./components/ORSchool";
import PrivacyPolicy from "./components/PrivacyPolicy";

const HomePage = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box position="relative">
        <HeroSection />
        {!isMobile && (
          <Box
            component="img"
            src={RightBlock}
            alt="RightBlock"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              mt: 8.5,
              mr: 15,
              maxHeight: "140%",
              display: { xs: "none", md: "block" },
            }}
          />
        )}
      </Box>
      <WhyDecisionOpt />
      <WorkDescription />
      <GetStarted
        title="Get Started"
        content={
          <>
            Partner with us to unlock your business's full potential. Our
            experts are ready to help you achieve your{" "}
            <strong>optimization goals.</strong>
          </>
        }
        buttonText="Contact Us"
        textColor="white"
        backgroundColor="#FE5D34"
        googleForm="https://forms.gle/LLFj8MSK8JkCrMc39"
      />
    </>
  );
};

const App = () => (
  <Router>
    <ThemeProvider theme={theme}>
      <Box sx={{ overflowX: "hidden" }}>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/corporate-training" element={<CorporateTraining />} />
          <Route path="/hiring-upskilling" element={<HiringUpskilling />} />
          <Route path="/consulting-strategy" element={<ConsultingStrategy />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/orschool" element={<ORSchoolOverview />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </Box>
    </ThemeProvider>
  </Router>
);

export default App;
