import React from 'react';
import { Container, Typography, Box, Avatar, Card, CardContent } from '@mui/material';
import AlokPatel from '../assets/AlokPatel.png';
import ArvindKumar from '../assets/ArvindKumar.png';

const AboutUs = () => {
  return (
    <Container maxWidth={false} sx={{ marginTop: '40px', maxWidth: '600px' }}>
      {/* Introduction */}
      <Typography sx={{fontSize: 48, mb:2}} gutterBottom>
        About Us
      </Typography>
      <Typography sx={{fontSize: 18, my: 2}}>
        Bringing Operations Research to the Forefront of Decision-Making
      </Typography>
      <Typography variant="body2" gutterBottom>
        At DecisionOpt, our mission is to make Operations Research (OR) a key player in the age of AI. We believe that
        the power of decision intelligence can transform industries and drive better outcomes across the globe. Our goal
        is to deliver cutting-edge OR solutions that enable organizations to make smarter, data-driven decisions.
      </Typography>

      {/* Profile Sections */}
      <Box sx={{ marginTop: '40px' }}>
        {/* Dr. Alok Patel */}
        <Card sx={{ textAlign: 'center', padding: '20px', marginBottom: '40px' }} elevation={0}>
          <Avatar
            alt="Dr. Alok Patel"
            src={AlokPatel}
            sx={{ width: 150, height: 150, margin: '0 auto', marginBottom: '20px' }}
          />
          <CardContent>
            <Typography variant="h6" component="h3" gutterBottom>
              Dr. Alok Patel
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Founder & CEO
            </Typography>
            <Typography variant="body2" paragraph>
              Dr. Alok Patel started DecisionOpt with a vision to bring the power of Operations Research to the
              forefront of business and technology. With a strong background in OR and a passion for solving complex
              problems, Dr. Patel has been instrumental in building a company that delivers value through innovative
              decision intelligence solutions.
            </Typography>
            <Typography variant="body2" sx={{ fontStyle: 'italic' }} paragraph>
              "I started this journey to make OR popular in the age of AI and to deliver value through decision
              intelligence to the entire world."
            </Typography>
            <Typography variant="body2" paragraph>
              Dr. Patel's experience spans various industries, including logistics, oil & gas, and e-commerce. His
              expertise in optimization modeling and decision support tools has made him a sought-after consultant in
              the field of Operations Research.
            </Typography>
          </CardContent>
        </Card>

        {/* Dr. Arvind Kumar */}
        <Card sx={{ textAlign: 'center', padding: '20px' }} elevation={0}>
          <Avatar
            alt="Dr. Arvind Kumar"
            src={ArvindKumar}
            sx={{ width: 150, height: 150, margin: '0 auto', marginBottom: '20px' }}
          />
          <CardContent>
            <Typography variant="h6" component="h3" gutterBottom>
              Dr. Arvind Kumar
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Senior Advisor & Mentor
            </Typography>
            <Typography variant="body2" paragraph>
              Dr. Arvind Kumar brings a wealth of knowledge and experience to DecisionOpt as a Senior Advisor and
              Mentor. With a Ph.D. in Operations Research and extensive industry experience, Dr. Kumar has been a
              guiding force in shaping our strategic direction.
            </Typography>
            <Typography variant="body2" paragraph>
              Dr. Kumar's expertise in OR, data analytics, and AI has been invaluable in helping DecisionOpt develop
              innovative solutions that meet the evolving needs of our clients. His mentorship ensures that we stay at
              the cutting edge of research and technology, providing our clients with the best possible solutions.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default AboutUs;
