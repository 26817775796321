import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  useMediaQuery,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ContactModal from "./ContactModal";
import MenuIcon from "@mui/icons-material/Menu";
import DecisionOptLogo from "../assets/DecisionOptLogo.svg";

const Header = () => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();

  const isHomePage = location.pathname === "/";
  const backgroundColor = isHomePage ? "#F2F2F2" : "#FFFFFF";

  const handleScrollToSection = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: sectionId } });
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    if (location.pathname === "/" && location.state?.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      // Clear the state to prevent scrolling on subsequent renders
      navigate("/", { replace: true, state: {} });
    }
  }, [location, navigate]);

  const handleOpenContactModal = () => {
    setIsContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuClick = (option) => {
    if (option === "What we offer") {
      handleScrollToSection("work-description");
    } else if (option === "OR School") {
      navigate("/orschool");
    } else if (option === "About us") {
      navigate("/about-us");
    }
  };

  const handleOptionClick = (option) => {
    if (option === "corporate-training") {
      navigate("/corporate-training");
    } else if (option === "hiring-upskilling") {
      navigate("/hiring-upskilling");
    } else if (option === "consulting-strategy") {
      navigate("/consulting-strategy");
    } else {
      handleScrollToSection(option);
    }
    handleMenuClose();
  };

  const open = Boolean(anchorEl);

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {["What we offer", "OR School", "About us"].map((text, index) => (
          <ListItem
            button
            key={text}
            onClick={() => handleMobileMenuClick(text)}
          >
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: backgroundColor,
          borderBottom: "1px solid #DBDBDB",
          color: "#000000", // Ensure text color remains black on white background
        }}
        elevation={0}
      >
        {isMobile ? (
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Box
                component="img"
                src={DecisionOptLogo}
                alt="DecisionOpt Logo"
                sx={{
                  height: 30,
                  cursor: "pointer",
                }}
              />
            </Link>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                sx={{
                  textTransform: "none",
                  color: "#000000",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "6px",
                  padding: "4px 12px",
                  boxShadow: "none",
                  border: "1px solid #DBDBDB",
                  whiteSpace: "nowrap",
                  marginRight: "16px",
                }}
                variant="contained"
                color="white"
                onClick={handleOpenContactModal}
              >
                Contact us
              </Button>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        ) : (
          <Toolbar
            sx={{ display: "flex", alignSelf: "center", overflowX: "auto" }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Box
                component="img"
                src={DecisionOptLogo}
                alt="DecisionOpt Logo"
                sx={{
                  marginRight: "64px",
                  height: { xs: 30, sm: "auto" },
                  minWidth: { xs: 100, sm: "auto" },
                  cursor: "pointer",
                }}
              />
            </Link>
            <Box
              sx={{
                display: "flex",
                gap: { xs: "12px", sm: "24px" },
                alignItems: "center",
                color: "#000000",
                flexShrink: 0,
              }}
            >
              <Button
                variant="text"
                sx={{ textTransform: "none", whiteSpace: "nowrap" }}
                color="inherit"
                onClick={handleMenuOpen}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                What We Offer
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  sx={{ fontSize: "14px", fontWeight: 500 }}
                  onClick={() => handleOptionClick("corporate-training")}
                >
                  Corporate Training
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: "14px", fontWeight: 500 }}
                  onClick={() => handleOptionClick("hiring-upskilling")}
                >
                  Hiring & Upskilling
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: "14px", fontWeight: 500 }}
                  onClick={() => handleOptionClick("consulting-strategy")}
                >
                  Consulting & Strategy
                </MenuItem>
              </Menu>
              <Link to="/orschool" style={{ textDecoration: "none" }}>
                <Button
                  variant="text"
                  sx={{
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    color: "#000000",
                  }}
                >
                  OR School
                </Button>
              </Link>
              <Link to="/about-us" style={{ textDecoration: "none" }}>
                <Button
                  variant="text"
                  sx={{
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    color: "#000000",
                  }}
                >
                  About Us
                </Button>
              </Link>
            </Box>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: "64px",
                color: "#000000",
                backgroundColor: "#FFFFFF",
                borderRadius: "6px",
                padding: "4px 12px",
                boxShadow: "none",
                border: "1px solid #DBDBDB",
                whiteSpace: "nowrap",
                flexShrink: 0,
              }}
              variant="contained"
              color="white"
              onClick={() => handleOpenContactModal()}
            >
              Contact us
            </Button>
          </Toolbar>
        )}
      </AppBar>
      <ContactModal
        open={isContactModalOpen}
        onClose={handleCloseContactModal}
      />
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        {drawerContent}
      </Drawer>
    </>
  );
};

export default Header;
