import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, Button, Grid, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import CurvedLines from "../assets/Circle.png";
import CorporateTraining from "../assets/CorporateTraining.png";
import HiringUpskilling from "../assets/CorporateTraining.png";
import ConsultingStrategy from "../assets/CorporateTraining.png";

const WorkDescription = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [selectedService, setSelectedService] = useState("Corporate Training");
  const [initialized, setInitialized] = useState(false);
  const selectedServiceImageRef = useRef(null);

  const serviceData = {
    "Corporate Training": {
      image: CorporateTraining,
      description:
        "Equip your engineers with expertise to maintain and support decision optimization tools.",
      link: "/corporate-training",
    },
    "Hiring & Upskilling": {
      image: HiringUpskilling,
      description: "Find and develop top talent for your organization's needs.",
      link: "/hiring-upskilling",
    },
    "Consulting & Strategy": {
      image: ConsultingStrategy,
      description:
        "Get expert advice to optimize your business processes and strategy.",
      link: "/consulting-strategy",
    },
  };

  const handleServiceClick = (serviceName) => {
    setSelectedService(serviceName);
  };

  const handleUserInteraction = () => {
    setInitialized(true);
  };

  useEffect(() => {
    if (selectedServiceImageRef.current && initialized) {
      selectedServiceImageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedService, initialized]);

  return (
    <Box
      id="work-description"
      sx={{
        backgroundColor: "#0d2fd2",
        color: "#FFFFFF",
        padding: "50px",
        position: "relative",
      }}
      onMouseMove={handleUserInteraction}
      onTouchStart={handleUserInteraction}
    >
      {!isMobile && (
        <>
          <Box
            component="img"
            src={CurvedLines}
            sx={{ position: "absolute", top: 48, left: 0, width: "100%" }}
          />
        </>
      )}

      <Grid
        sx={{
          mt: { xs: 2, sm: 4, md: 24, lg: 32 },
          mb: { xs: 6, md: 12 },
        }}
        container
        spacing={6}
      >
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 24 }} variant="overline">
            WHAT-WE-DO
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          container
          direction="column"
          justifyContent="flex-start"
        >
          {Object.keys(serviceData).map((serviceName) => (
            <Button
              key={serviceName}
              onClick={() => handleServiceClick(serviceName)}
              sx={{
                width: "fit-content",
                fontSize: { xs: 24, md: 36 },
                mb: { xs: 3, md: 4 },
                color: "#FFFFFF",
                textTransform: "none",
                justifyContent: "flex-start",
                padding: 0,
                opacity: selectedService === serviceName ? 1 : 0.7,
                fontWeight: selectedService === serviceName ? "bold" : "normal",
              }}
            >
              {serviceName}
            </Button>
          ))}
        </Grid>

        <Grid
          sx={{ pr: { xs: 0, md: 12 } }}
          item
          xs={12}
          sm={6}
          container
          direction="column"
          justifyContent="space-between"
        >
          <Box>
            <Box
              component="img"
              ref={selectedServiceImageRef}
              src={serviceData[selectedService].image}
              alt={selectedService}
              sx={{
                marginRight: { xs: "32px", md: "64px" },
                width: "50%",
                maxWidth: "400px",
              }}
            />
            <Typography
              variant="body1"
              sx={{
                marginTop: 3,
                fontSize: { xs: 24, md: 36 },
                fontWeight: "normal",
              }}
            >
              {serviceData[selectedService].description}
            </Typography>
          </Box>
          <Button
            component={Link}
            to={serviceData[selectedService].link}
            variant="outlined"
            sx={{
              color: "#FFFFFF",
              borderColor: "#FFFFFF",
              marginTop: "24px",
              padding: "10px 20px",
              textTransform: "none",
              borderRadius: "25px",
              fontSize: 16,
              "&:hover": {
                borderColor: "#FFFFFF",
              },
              maxWidth: "200px",
            }}
          >
            Find out more →
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WorkDescription;
