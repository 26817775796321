import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Snackbar,
  IconButton,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ContactModal = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    emailjs.init("alok@decisionopt.com"); // Replace with your EmailJS user ID
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await emailjs.send(
        process.env.NEXT_PUBLIC_EMAILJS_SERVICE_ID,
        process.env.NEXT_PUBLIC_EMAILJS_TEMPLATE_ID,
        {
          from_name: formData.name,
          from_email: formData.email,
          phone: formData.phone,
          message: formData.message,
        }
      );
      setSnackbarOpen(true);
      onClose();
    } catch (error) {
      console.error("Error sending email:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="contact-modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: 400 }, // Adjust width for mobile
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            p: 4,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="contact-modal-title"
            align="center"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Contact Us
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              margin="normal"
              required
              variant="outlined"
              inputProps={{ style: { fontSize: "1rem" } }} // Adjust font size for mobile
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
              required
              variant="outlined"
              inputProps={{ style: { fontSize: "1rem" } }} // Adjust font size for mobile
            />
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              multiline
              value={formData.phone}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              inputProps={{ style: { fontSize: "1rem" } }} // Adjust font size for mobile
            />
            <TextField
              fullWidth
              label="Message"
              name="message"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleChange}
              margin="normal"
              required
              variant="outlined"
              inputProps={{ style: { fontSize: "1rem" } }} // Adjust font size for mobile
            />
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: "#143BE2",
                  "&:hover": { bgcolor: "#0f2eb3" },
                  px: 4,
                  py: 1,
                }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          We will get back to you soon!
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactModal;
