import React from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import HiringUpskillingPic1 from "../assets/HiringUpskillingPic1.png";
import HiringUpskillingPic2 from "../assets/HiringUpskillingPic2.png";
import GetStarted from "./GetStarted";

const ServiceList = ({ services }) => (
  <List>
    {services.map((service, index) => (
      <ListItem key={index} alignItems="flex-start">
        <ListItemText
          primary={
            <Typography variant="body1" component="span" fontWeight="bold">
              {service.title}
            </Typography>
          }
          secondary={
            <Typography variant="body2">{service.description}</Typography>
          }
        />
      </ListItem>
    ))}
  </List>
);

const HiringUpskilling = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const candidateServices = [
    {
      title: "Personalized Guidance:",
      description:
        "We take the time to understand your career aspirations, skills, and expectations. Our experts provide personalized advice to help you identify the best opportunities.",
    },
    {
      title: "Skill Enhancement:",
      description:
        "If you need to upskill or gain new certifications, we guide you on the most relevant courses and training programs.",
    },
    {
      title: "Opportunity Matching:",
      description:
        "We connect you with leading companies that are looking for OR talent. Whether you are seeking a full-time role or a contract position, we find the right fit for you.",
    },
  ];

  const corporateServices = [
    {
      title: "Talent Sourcing:",
      description:
        "We identify and screen OR professionals to match your specific needs, ensuring you have access to the best talent available for full-time or contract roles.",
    },
    {
      title: "Market Insights:",
      description:
        "For companies planning to establish or expand their OR teams in India, we provide valuable insights into the job market, including talent availability and compensation benchmarking.",
    },
    {
      title: "Tailored Hiring Solutions:",
      description:
        "Whether you need a single OR expert or a complete team, our services are flexible and tailored to meet your organization's unique requirements.",
    },
  ];

  return (
    <Container maxWidth="md">
      <Box my={isMobile ? 2 : 4}>
        <Typography
          variant={isMobile ? "h4" : "h3"}
          component="h1"
          gutterBottom
        >
          Hiring & Upskilling
        </Typography>

        <Card sx={{ mb: isMobile ? 2 : 4 }}>
          <CardMedia
            component="img"
            height={isMobile ? "300" : "500"}
            image={HiringUpskillingPic1}
            alt="Training session"
          />
        </Card>

        <Typography variant="body1" sx={{ my: isMobile ? 2 : 4 }}>
          At DecisionOpt, we specialize in connecting talented Operations
          Research (OR) professionals with organizations looking to enhance
          their decision-making capabilities. Whether you are a candidate
          seeking new opportunities or a corporate client in need of expert OR
          talent, we are here to help.
        </Typography>

        <Typography
          variant={isMobile ? "h5" : "h4"}
          component="h2"
          gutterBottom
        >
          For Candidates : Discover Your Next Big Opportunity in{" "}
          <Typography
            sx={{ fontSize: isMobile ? 24 : 36 }}
            component="span"
            color="#143BE2"
          >
            OR
          </Typography>
        </Typography>

        <Typography variant="body1" paragraph>
          Are you an OR professional looking for your next challenge?
          DecisionOpt can help you take the next step in your career. We
          understand the unique skills and aspirations of OR specialists, and we
          are committed to finding the perfect match for your career goals.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Our Services for Candidates:
        </Typography>

        <ServiceList services={candidateServices} />
        <GetStarted
          topMargin="8"
          textColor="black"
          title="Application Form"
          content="Discover Your Next Big Opportunity in OR"
          backgroundColor="#F6F6F6"
          buttonText="Apply now"
          googleForm="https://docs.google.com/forms/d/e/1FAIpQLSfNN7ZhQD2p-gENgwUwar2gVKMGIasjqhWiAyfQxaA7NN_RCA/viewform"
        />
        <Card sx={{ my: isMobile ? 2 : 4 }}>
          <CardMedia
            component="img"
            height={isMobile ? "300" : "500"}
            image={HiringUpskillingPic2}
            alt="Professional audience"
          />
        </Card>

        <Typography sx={{ fontSize: isMobile ? 24 : 36 }} gutterBottom>
          For Corporates : Your{" "}
          <Typography
            sx={{ fontSize: isMobile ? 24 : 36 }}
            component="span"
            color="#143BE2"
          >
            One-Stop Solution
          </Typography>{" "}
          for Hiring OR Talent
        </Typography>

        <Typography variant="body1" paragraph>
          Finding the right Operations Research talent can be challenging. At
          DecisionOpt, we make it easy for you to hire skilled OR professionals
          who can drive value and innovation in your organization.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Our Services for Corporates:
        </Typography>

        <ServiceList services={corporateServices} />
      </Box>
      <GetStarted
        topMargin="8"
        textColor="black"
        title="Application Form"
        content="Your One-Stop Solution for Hiring OR Talent."
        backgroundColor="#F6F6F6"
        buttonText="Apply now"
        googleForm="https://docs.google.com/forms/d/e/1FAIpQLSe6JSrQLrYSKOdi2giZA4zjxEaFxM05GOQ9B-w5fHDOPeNpMA/viewform"
      />
    </Container>
  );
};

export default HiringUpskilling;
