import React from "react";
import { Typography, Container, Box } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          At DecisionOpt ("we," "us," or "our"), your privacy is important to
          us. This Privacy Policy explains how we collect, use, and protect your
          personal information when you interact with our services.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We may collect the following types of personal information when you
          use our services:
        </Typography>
        <Typography component="ul">
          <Typography component="li">
            Contact Information: When you engage with our services or contact
            us, we may collect details such as your name, email, and
            organization name.
          </Typography>
          <Typography component="li">
            Service Data: Information related to your use of our optimization
            solutions, including project details, queries, and interactions with
            our tools.
          </Typography>
          <Typography component="li">
            Payment Information: We may collect billing information when you
            make purchases, though we do not store credit card details directly.
            Payment processing is handled by third-party services.
          </Typography>
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1">We use your personal data to:</Typography>
        <Typography component="ul">
          <Typography component="li">
            Provide, maintain, and improve our services.
          </Typography>
          <Typography component="li">
            Respond to your inquiries, requests, and provide support.
          </Typography>
          <Typography component="li">
            Process payments and manage billing.
          </Typography>
          <Typography component="li">
            Enhance your experience by understanding your needs and preferences.
          </Typography>
          <Typography component="li">
            Comply with legal obligations and secure our services from
            unauthorized activities.
          </Typography>
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          3. Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We do not sell or rent your personal information. We may share your
          data with:
        </Typography>
        <Typography component="ul">
          <Typography component="li">
            Service Providers: Third-party providers who help us operate our
            business (e.g., payment processors, hosting services), with whom we
            share necessary information under strict confidentiality agreements.
          </Typography>
          <Typography component="li">
            Legal Compliance: When required by law or to protect our rights or
            the safety of our users.
          </Typography>
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          4. Data Retention
        </Typography>
        <Typography variant="body1" paragraph>
          We will retain your personal information for as long as necessary to
          fulfill the purposes outlined in this policy or as required by law.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          5. Security
        </Typography>
        <Typography variant="body1" paragraph>
          We employ industry-standard security measures to protect your personal
          data from unauthorized access, disclosure, alteration, or destruction.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          6. Your Choices
        </Typography>
        <Typography component="ul">
          <Typography component="li">
            Email Communications: You can opt out of marketing emails at any
            time by using the unsubscribe link provided in the email.
            Transactional communications related to services will continue as
            necessary.
          </Typography>
          <Typography component="li">
            Cookies: You can adjust your browser settings to manage cookies,
            though this may affect the functionality of our services.
          </Typography>
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          7. Changes to this Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy periodically. The latest version
          will always be available on our website.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          8. Contact Us
        </Typography>
        <Typography variant="body1">
          For any questions or concerns, please contact us at:
        </Typography>
        <Typography variant="body1">Email: hello@decisionopt.com</Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
