import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import ORSchoolImage from "../assets/SchoolBackground.png";
import ProgramOverview from "./ORSchool/ProgramOverview";
import EligibilityRequirements from "./ORSchool/EligibilityRequirements";
import IndustrialReadyCurriculum from "./ORSchool/Curriculum";
import Placement from "./ORSchool/Placement";
import MeetYourInstructors from "./ORSchool/Instructors";
import GetStarted from "./GetStarted";

const ORSchoolOverview = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <img
          src={ORSchoolImage}
          alt="ORSchool"
          style={{ width: "100%", height: "auto", objectFit: "cover" }}
        />
        <Box
          position="absolute"
          left={isMobile ? "50%" : "12%"}
          sx={{
            transform: isMobile ? "translateX(-50%)" : "none",
            textAlign: isMobile ? "center" : "left",
            width: isMobile ? "90%" : "auto",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "28px",
                sm: "36px",
                md: "50px",
                lg: "60px",
              },
              fontWeight: "bold",
            }}
            gutterBottom
            color="white"
          >
            OR School
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "22px" },
            }}
            color="black"
          >
            Become an <strong>OR Scientist/Engineer</strong>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          px: { xs: "16px", sm: "32px", md: "80px", lg: "170px" },
          py: "48px",
          display: "flex",
          flexDirection: "column",
          alignItems: isMobile ? "center" : "flex-start",
        }}
      >
        <Box
          sx={{
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "48px",
            backgroundColor: "#F6F6F6",
            width: "100%",
            maxWidth: "600px",
            py: "16px",
            px: { xs: "16px", sm: "24px" },
            textAlign: isMobile ? "center" : "left",
          }}
        >
          <Typography sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}>
            30 Weeks Full-Time Program with Live Classes and Hands-On Training.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "40px", sm: "60px", md: "80px" },
            alignItems: isMobile ? "center" : "stretch",
            width: "100%",
          }}
        >
          <ProgramOverview />
          <EligibilityRequirements />
          <IndustrialReadyCurriculum />
          <Placement />
          <MeetYourInstructors />
        </Box>
      </Box>
      <GetStarted
        title="Stay Tuned"
        content="We are in the final stages of finalizing our program and curriculum details. Sign up for updates and be the first to know about new developments and announcements."
        buttonText="Join the Waitlist"
        backgroundColor="#FE5D34"
        googleForm="https://forms.gle/LLFj8MSK8JkCrMc39"
        useGoogleForm={true}
        bottomMargin={20}
      />
    </Box>
  );
};

export default ORSchoolOverview;
