import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

const EligibilityRequirements = () => {
  return (
    <Box sx={{ padding: { xs: "10px", sm: "20px" } }}>
      <Typography variant="h5" gutterBottom>
        <Box
          component="span"
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            width: { xs: "40px", sm: "50px" }, // Responsive width
            height: { xs: "40px", sm: "50px" }, // Responsive height
            borderRadius: "50%",
            backgroundColor: "#F6F6F6",
            color: "black",
            marginRight: { xs: "20px", sm: "36px" }, // Responsive margin
            fontSize: { xs: "20px", sm: "24px" }, // Responsive font size
          }}
        >
          2
        </Box>
        <strong>Eligibility Requirements</strong>
      </Typography>
      <Box
        sx={{
          backgroundColor: "#f4f4f4",
          padding: { xs: "15px", sm: "20px" }, // Responsive padding
          borderRadius: "5px",
          ml: { xs: "0", sm: "86px" }, // Responsive margin-left
          mt: "20px",
        }}
      >
        <Typography sx={{ fontSize: { xs: "16px", sm: "18px" } }} gutterBottom>
          In order to join DecisionOpt OR School, you must meet the following
          eligibility criteria:
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary={
                <strong style={{ fontSize: { xs: "16px", sm: "18px" } }}>
                  {">"} Qualification
                </strong>
              }
              secondary={
                <>
                  <Typography component="div" sx={{ mt: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box component="span" sx={{ mr: 1, ml: 1 }}>
                        •
                      </Box>
                      <Box
                        component="span"
                        sx={{ fontSize: "18px", color: "black" }}
                      >
                        Operations Research
                      </Box>
                    </Box>
                  </Typography>
                  <Typography component="div">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box component="span" sx={{ mr: 1, ml: 1 }}>
                        •
                      </Box>
                      <Box
                        component="span"
                        sx={{ fontSize: "18px", color: "black" }}
                      >
                        Industrial Engineering
                      </Box>
                    </Box>
                  </Typography>
                  <Typography component="div">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box component="span" sx={{ mr: 1, ml: 1 }}>
                        •
                      </Box>
                      <Box
                        component="span"
                        sx={{ fontSize: "18px", color: "black" }}
                      >
                        Industrial Management
                      </Box>
                    </Box>
                  </Typography>
                  <Typography component="div">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box component="span" sx={{ mr: 1, ml: 1 }}>
                        •
                      </Box>
                      <Box
                        component="span"
                        sx={{ fontSize: "18px", color: "black" }}
                      >
                        Production Engineering
                      </Box>
                    </Box>
                  </Typography>
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              sx={{ fontSize: "18px" }}
              primary={<strong>{">"} Graduation Period</strong>}
              secondary={
                <Typography component="div" sx={{ mt: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box component="span" sx={{ mr: 1, ml: 1 }}>
                      •
                    </Box>
                    <Box
                      component="span"
                      sx={{ fontSize: "18px", color: "black" }}
                    >
                      Must have graduated in the last two years from a
                      recognized Indian university
                    </Box>
                  </Box>
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              sx={{ fontSize: "18px" }}
              primary={<strong>{">"} Employment Status</strong>}
              secondary={
                <Typography component="div" sx={{ mt: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box component="span" sx={{ mr: 1, ml: 1 }}>
                      •
                    </Box>
                    <Box
                      component="span"
                      sx={{ fontSize: "18px", color: "black" }}
                    >
                      Must not be currently employed
                    </Box>
                  </Box>
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              sx={{ fontSize: "18px" }}
              primary={<strong>{">"} Internet Access</strong>}
              secondary={
                <Typography component="div" sx={{ mt: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box component="span" sx={{ mr: 1, ml: 1 }}>
                      •
                    </Box>
                    <Box
                      component="span"
                      sx={{ fontSize: "18px", color: "black" }}
                    >
                      Desktop or laptop with reliable internet
                    </Box>
                  </Box>
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              sx={{ fontSize: "18px" }}
              primary={<strong>{">"} Communication Skills</strong>}
              secondary={
                <Typography component="div" sx={{ mt: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box component="span" sx={{ mr: 1, ml: 1 }}>
                      •
                    </Box>
                    <Box
                      component="span"
                      sx={{ fontSize: "18px", color: "black" }}
                    >
                      Basic proficiency in English (speaking, reading, writing)
                    </Box>
                  </Box>
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              sx={{ fontSize: "18px" }}
              primary={<strong>{">"} Identity</strong>}
              secondary={
                <Typography component="div" sx={{ mt: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box component="span" sx={{ mr: 1, ml: 1 }}>
                      •
                    </Box>
                    <Box
                      component="span"
                      sx={{ fontSize: "18px", color: "black" }}
                    >
                      Valid Aadhaar Card
                    </Box>
                  </Box>
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default EligibilityRequirements;
