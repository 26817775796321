import React from "react";
import { Box, Typography, Link, IconButton, Divider, useMediaQuery, useTheme } from "@mui/material";
import LinkedIn from '../assets/LinkedIn.svg';
import Location from '../assets/Location.svg';
import Email from '../assets/Email.svg';
import GrayLogo from '../assets/GrayLogo.svg';


const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        backgroundColor: "#000",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: isMobile ? "20px 0" : "40px 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: isMobile ? "center" : "flex-start",
          width: "90%",
          maxWidth: "1200px",
        }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: isMobile ? 20 : 24,
            mt: 2,
            mb: isMobile ? 2 : 0,
          }}
        >
          <img
            src={GrayLogo}
            alt="DecisionOpt Logo"
            style={{ height: isMobile ? "40px" : "50px", marginRight: "10px" }}
          />
          DecisionOpt
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: isMobile ? "center" : "baseline",
          }}
        >
          <Box sx={{ display: "flex", mb: "15px" }}>
            <IconButton
              component={Link}
              href="https://www.linkedin.com/company/decisionopt/"
              target="_blank"
              rel="noopener"
              sx={{ color: "#fff", marginLeft: "15px" }}
            >
              <Box component="img" src={LinkedIn} alt="LinkedIn Logo" />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: "10px" }}>
            <Box
              component="img"
                src={Location}
              alt="Location Logo"
              sx={{ marginRight: "10px" }}
            />
            <Typography variant="body2">Bengaluru, India</Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box
              component="img"
              src={Email}
              alt="Email Logo"
              sx={{ marginRight: "10px" }}
            />
            <Link
              href="mailto:hello@decisionopt.com"
              sx={{ color: "#fff", textDecoration: "none" }}
            >
              <Typography variant="body2">hello@decisionopt.com</Typography>
            </Link>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "30px",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "center" : "flex-start",
          justifyContent: "center",
          width: "100%",
          paddingTop: "20px",
        }}
      >
        <Link href="/about-us" sx={{ color: "#fff", margin: isMobile ? "10px 0" : "0 15px" }}>
          About
        </Link>
        {!isMobile && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{ backgroundColor: "#fff", margin: "0 15px" }}
          />
        )}
        <Link href="/privacy-policy" sx={{ color: "#fff", margin: isMobile ? "10px 0" : "0 15px" }}>
          Privacy Policy
        </Link>
        {!isMobile && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{ backgroundColor: "#fff", margin: "0 15px" }}
          />
        )}
        <Link href="/support" sx={{ color: "#fff", margin: isMobile ? "10px 0" : "0 15px" }}>
          Support
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
