import {
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const WhyDecisionOpt = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      id="why-decision-opt"
      sx={{
        mt: { xs: 8, md: 24 },
        px: { xs: 2, md: 19 },
        mb: { xs: 8, md: 24 },
      }}
    >
      <Typography
        sx={{ fontSize: { xs: 28, md: 36 }, fontWeight: 600 }}
        gutterBottom
      >
        Why DecisionOpt?
      </Typography>
      <Typography sx={{ mt: 3, fontSize: { xs: 16, md: 18 } }}>
        In a highly competitive business landscape, optimization is a continuous
        process. We build decision support tools and offer ongoing support to
        keep your business ahead of the curve, providing continuous improvements
        and expert guidance tailored to your evolving needs.
      </Typography>
      <Box sx={{ position: "relative", mt: 5, maxWidth: "100%" }}>
        <Divider
          sx={{
            display: { xs: "none", md: "block" },
            position: "absolute",
            top: "50%",
            left: "-50vw", // Position it off-screen to the left
            width: "200vw", // Make it twice the viewport width
            zIndex: 0,
            borderBottomWidth: 2, // Increase thickness
            borderColor: "black", // Change color to black
          }}
        />
        <Grid container spacing={3}>
          {[
            [
              "Process Efficiency Optimization",
              "Streamline your operations to achieve maximum efficiency and effectiveness.",
            ],
            [
              "Strategic Business Solutions",
              "Develop and implement modern operational strategies tailored to your unique needs.",
            ],
            [
              "Seamless Solution Implementation",
              "Our experts ensure smooth and successful decision intelligence integrations for your business.",
            ],
          ].map(([title, description]) => (
            <Grid item xs={12} md={4} key={title}>
              <Card
                sx={{
                  border: "1px solid #E0E0E0",
                  borderRadius: "8px",
                  backgroundColor: "#F2F2F2",
                  minHeight: { xs: "auto", md: 206 },
                  mb: { xs: 2, md: 0 },
                  mx: { xs: 0, md: 1.5 },
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ fontWeight: 700, fontSize: { xs: 20, md: 24 } }}
                    gutterBottom
                  >
                    {title}
                  </Typography>
                  <Typography sx={{ mt: 2, fontSize: 16, fontWeight: 400 }}>
                    {description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default WhyDecisionOpt;
